<template>
	<div v-if="config.show != 0"
		style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
		:style="{
			backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
		}">
		<div :style="{
			opacity: config.show <= 1 ? '0' : '1',
			scale: config.show <= 1 ? '1.1' : '1',
			zoom: tool.pageZoom()
		}" style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-width: calc(100vw - 40px);max-height: calc(100vh - 40px);width: 600px;height: auto;overflow-y: auto;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
			<div style="width: 100%;height: 24px;">
				<div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
					{{ config.placeholder }}
				</div>
				<div style="display: inline-block;height: 100%;float: right;">
					<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
						<svg @click="() => {
							config.control(false)
						}" v-if="config.callBack != undefined && config.showCancle == true" style="float: right;" width="24" height="24"
							viewBox="0 0 48 48" fill="currentColor">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
								fill="currentColor" />
						</svg>
					</div>
				</div>
			</div>
			<input id="inputForm_input" @keypress="(e) => {
				// 11 // 11_2 // console.log(e.code)
				if (e.code == 'Enter' || e.code == 'NumpadEnter') {
					if (config.callBack != undefined) {
						config.callBack(config.input)
					}
					config.control(false)
				}
			}" v-if="config.textArea == false" :placeholder="''" type="text" v-model="config.input" :style="{
				border: 0 ? '1px solid red' : '1px solid #f6f8f7',
				color: 0 ? 'red' : '#2e4232'
			}" style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 16px 16px;font-family: 微软雅黑;font-size: 18px;margin-top: 24px;width: 100%;">
			<textarea id="inputForm_textarea" v-if="config.textArea == true" :placeholder="''" type="text"
				v-model="config.input" :style="{
					border: 0 ? '1px solid red' : '1px solid #f6f8f7',
					color: 0 ? 'red' : '#2e4232'
				}" style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 16px 16px;font-family: 微软雅黑;font-size: 18px;margin-top: 24px;width: 100%;max-height: 300px;min-height: 300px;max-width: 100%;min-width: 100%;"></textarea>
			<wangEditor id="inputForm_editor" v-if="config.textArea == 3" :value="config.input" @input="(e) => {
				config.input = e
			}"></wangEditor>
			<div
				style="width: 100%;margin-top: 36px;display: flex;align-items: center;justify-content: center;gap: 8px;">
				<yButton class="activeScale" v-if="config.callBack != undefined && config.showCancle == true" @click="() => {
					config.control(false)
				}" style="width: 100%;">取消</yButton>
				<yButton class="activeScale" @click="() => {
					if (config.callBack != undefined) {
						config.callBack(config.input)
					}
					config.control(false)
				}" color="white" background-color="#24c27d" mask-color="white" style="width: 100%;">确定
				</yButton>
			</div>
		</div>
	</div>
</template>

<script setup>
import wangEditor from './wangEditor.vue';
import {
	ref,
	reactive,
	defineProps,
	computed,
	watch,
	onMounted,
	onBeforeUnmount
} from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const config = ref({
	show: 0,
	input: '',
	placeholder: '',
	showCancle: true,
	textArea: false,
	callBack: () => { },
	async control(op, input = '', placeholder = '', callBack = () => { }, textArea = false) {
		if (op) {
			this.input = input
			this.placeholder = placeholder
			this.textArea = textArea
			this.callBack = callBack
			this.show = 1
			await tool.wait(0.001)
			this.show = 2
			if (textArea == false) {
				document.getElementById('inputForm_input').focus()
			}
			if (textArea == true) {
				document.getElementById('inputForm_textarea').focus()
			}
			if (textArea == 3) {
				document.getElementById('inputForm_editor').focus()
			}
		} else {
			this.show = 1
			await tool.wait(0.2)
			this.show = 0
		}
	}
})

onMounted(async () => {
	store.onChange('controlInputForm', () => {
		let data = store.getItem('controlInputForm')
		config.value.control(data.op, data.input, data.placeHolder, data.callBack, data.textArea)
	})
})
</script>

<style scoped></style>