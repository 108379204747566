import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import images from './assets/images';
import tool from './module/tool';
import store from './module/store';
import VueLazyload from 'vue-lazyload';

import Toast from "vue-toastification";
import { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App);

// 配置全局属性
app.config.globalProperties.$tool = tool;
app.config.globalProperties.$store = store;
app.config.globalProperties.$images = images;
app.config.globalProperties.$router = router;

// 使用 vue-lazyload
app.use(VueLazyload, {
  preLoad: 1.3,
  // error: 'path/to/error.png', // 替换为你的错误图像路径
  loading: images.loading, // 替换为你的加载图像路径
  attempt: 1,
});

// 挂载应用
app
  .use(router)
  .use(Toast, {
    position: POSITION.TOP_CENTER,
    timeout: 1000, // 3秒后自动消失
    hideProgressBar: true, // 隐藏进度条

  })
  .mount('#app');