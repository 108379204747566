const qs = require('qs')
import axios from 'axios'
import config from '../../config'
import images from '@/assets/images'
import store from './store'
import tool from './tool'
import encodeDecodePassword from './encodeDecodePassword'
import router from '@/router'
import fakeDataGenerator from './fakeDataGenerator'
import {
	computed
} from 'vue'
import html2canvas from 'html2canvas'
import qrcode from 'qrcode'
import { useToast } from "vue-toastification";

export default {
	tst: useToast(),
	toast(str) {
		if (str.indexOf('成功') != -1) {
			this.tst.success(str, {
				timeout: store.getItem('toastTime') ? store.getItem('toastTime') : 1000
			})
			this.setToastTime(1)
			return
		}
		if ((str.indexOf('失败') != -1 || str.indexOf('错误') != -1 || str.indexOf('不') != -1) && str.indexOf('请求失败') != -1) {
			this.tst.error(str, {
				timeout: store.getItem('toastTime') ? store.getItem('toastTime') : 1000
			})
			this.setToastTime(1)
			return
		}
		this.tst.info(str, {
			timeout: store.getItem('toastTime') ? store.getItem('toastTime') : 1000
		})
		this.setToastTime(1)
	},
	requesting: false,
	async requestData(path = '', data = {}, method = 'post', loading = true, forceNotUserRO = false) {
		if (this.requesting) {
			return {
				status: 0,
				content: '请求过于频繁'
			}
		}
		// this.requesting = true
		let requestEnd = false
		const handleTokenFail = async () => {
			this.localStorage.removeItem('userData')
			this.framePostMessageToParent({
				op: 'exitLoginAndReload'
			})

			if (store.getItem('userType') == undefined) {
				router.push('/login')
			} else {
				if (store.getItem('userType').indexOf('student') != -1) {
					router.push('/login')
				} else {
					router.push('/login')
				}
			}
			await this.wait(0.1)
			location.reload()
		}
		const requestOnce = {
			// pathList: ['/get_region', '/mainpage', '/student/get_first_list', '/student/get_second_list',
			// 	'/student/get_third_list'
			// ],
			pathList: [
			],
			// pathList: ['/get_region'],
			add(path, res, data) {
				if (this.pathList.indexOf(path) == -1) {
					return
				}
				if (data == undefined) data = {}
				data = JSON.stringify(data)
				let list = store.getItem('requestOnceData')
				if (list == undefined) list = []
				for (let i = 0; i < list.length; i++) {
					if (list[i].path == path && list[i].data == data) {
						return
					}
				}
				list.push({
					path: path,
					res: res,
					data: data
				})
				store.setItem('requestOnceData', list)
				// 11 // 11_2 // console.log(list)
			},
			get(path, data) {
				if (forceNotUserRO == true) {
					return {
						status: 0,
						data: undefined
					}
				}
				let list = store.getItem('requestOnceData')
				if (data == undefined) data = {}
				data = JSON.stringify(data)
				if (list == undefined) list = []
				for (let i = 0; i < list.length; i++) {
					if (list[i].path == path && list[i].data == data) {
						return {
							status: 1,
							data: list[i].res
						}
					}
				}
				return {
					status: 0,
					data: undefined
				}
			}
		}
		let tryRequestOnce = requestOnce.get(path, data)
		if (tryRequestOnce.status) {
			return {
				status: 1,
				data: tryRequestOnce.data,
				content: 'success'
			}
		}
		const showLoading = async () => {
			if (loading == false) return
			store.setItem('controlLoading', {
				op: true,
				visible: false
			})
			setTimeout(() => {
				if (requestEnd) return
				store.setItem('controlLoading', {
					op: true
				})
			}, 1000)
		}
		const hideLoading = async () => {
			requestEnd = true
			this.requesting = false
			store.setItem('controlLoading', {
				op: false
			})
			await tool.wait(0.01)
		}
		if (config.allowUseFakeData) {
			showLoading()
			await tool.wait(0.5)
			hideLoading()
			let re = fakeDataGenerator.get(path, data)
			return re
		}
		const requestTime = {
			startTime: new Date(),
			endTime: undefined,
			setEndTime() {
				this.endTime = new Date()
			},
			getTimeString() {
				return ((this.endTime - this.startTime) / 1000).toString() + '秒'
			}
		}
		return new Promise((resolve, reject) => {
			const handleResolve = (re) => {
				if (path.indexOf('/get_question_id_list') != -1) {
					re.data2 = re.data.from.replace('-', ' > ').replace('-', ' > ').replace('-', ' > ')
					re.data = re.data.list
				}
				if (path.indexOf('/get_san_li_question_detail') != -1) {
					try {
						if (!re.data.guanfangjieshi) re.data.guanfangjieshi = ''
					} catch (error) {

					}
					try {
						if (!re.data.jiqiaobiaohong) re.data.jiqiaobiaohong = ''
					} catch (error) {

					}
				}
				resolve(re)
			}
			let timer = setTimeout(async () => {
				await hideLoading()
				resolve({
					status: 0,
					content: '请求超时'
				})
				return
			}, 10000)
			if (method == 'get') {
				showLoading()
				axios.get(this.requestURLPathNormalization(config.serverURL + path) + '?' + qs.stringify(
					data), {
					headers: {
						satoken: store.getItem('userData_satoken')
					}
				}).then(async res => {
					requestOnce.add(path, res.data.data, data)
					fakeDataGenerator.insert(path, data, res.data)
					await hideLoading()
					requestTime.setEndTime()
					if (res.data.status == -1) {
						clearTimeout(timer)
						handleTokenFail()
						return
					}
					return handleResolve(res.data)
				}).catch(async e => {
					await hideLoading()
					requestTime.setEndTime()
					return resolve({
						status: 0,
						content: `<div style="font-size: 16px;">请求失败</div>
							<div style="margin-top: 0px;font-size:14px;color:gray">错误已在控制台输出</div>
						`
					})
					// 11_2 // console.log(e)
					// return resolve({
					// 	status: 0,
					// 	content: '<div style="font-family: 微软雅黑">地址：' + this
					// 		.requestURLPathNormalization(config.serverURL + path) +
					// 		'?' + qs.stringify(data) + '</div>' +
					// 		'<div style="font-family: 微软雅黑">错误信息：' + e.toString() +
					// 		'</div>' +
					// 		'<div style="font-family: 微软雅黑">请求用时：' + requestTime
					// 			.getTimeString() + '</div>' +
					// 		'<div style="font-family: 微软雅黑">方法：' + method + '</div>' +
					// 		'<div style="user-select:text;font-family:微软雅黑">输入：' + JSON
					// 			.stringify(data) + '</div>'
					// })
				})
			} else {
				showLoading()
				axios.post(this.requestURLPathNormalization(config.serverURL + path), data, {
					headers: {
						satoken: store.getItem('userData_satoken')
					}
				}).then(async res => {
					requestOnce.add(path, res.data.data, data)
					fakeDataGenerator.insert(path, data, res.data)
					await hideLoading()
					requestTime.setEndTime()
					if (res.data.status == -1) {
						clearTimeout(timer)
						handleTokenFail()
						return
					}
					return handleResolve(res.data)
				}).catch(async e => {
					await hideLoading()
					requestTime.setEndTime()
					return resolve({
						status: 0,
						content: `<div style="font-size: 16px;">请求失败</div>
							<div style="margin-top: 0px;font-size:14px;color:gray">错误已在控制台输出</div>
						`
					})
					// 11_2 // console.log(e)
					// return resolve({
					// 	status: 0,
					// 	content: '<div style="font-family: 微软雅黑">地址：' + this
					// 		.requestURLPathNormalization(config.serverURL + path) +
					// 		'</div>' +
					// 		'<div style="font-family: 微软雅黑">错误信息：' + e.toString() +
					// 		'</div>' +
					// 		'<div style="font-family: 微软雅黑">请求用时：' + requestTime
					// 			.getTimeString() + '</div>' +
					// 		'<div style="font-family: 微软雅黑">方法：' + method + '</div>' +
					// 		'<div style="user-select:text;font-family:微软雅黑">输入：' + JSON
					// 			.stringify(data) + '</div>'
					// })
				})
			}
		})

	},
	wait(n) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				return resolve()
			}, n * 1000);
		})
	},
	warningFormData: {
		operations: [],
		addAndShow(e) {
			this.operations.push({
				time: new Date(),
				data: e
			})
		},
		exec(time, buttonIndex) {
			for (let i = 0; i < this.operations.length; i++) {
				if (this.operations[i].time == time) {
					if (buttonIndex == 1) {
						try {
							this.operations[i].data.callBack()
						} catch (error) {

						}
					}
					if (buttonIndex == 2) {
						try {
							this.operations[i].data.cancleCallBack()
						} catch (error) {

						}
					}
				}
			}
		},
		handleExecMsg(e) {

		}
	},
	setToastTime(n = 1) {
		store.setItem('toastTime', n * 1000)
	},
	controlWarningForm(op = false, text = '', callBack = undefined, cancleCallBack = undefined, showCancle = true,
		okText = '确定', cancleText = '取消', title = '提示') {
		if (op == 1 && (!showCancle || callBack == undefined) && text.indexOf('请求失败') == -1) {
			try {
				callBack()
			} catch (error) {

			}
			this.toast(this.stringDeleteSubString(text, 'Error:'))
			return
		}
		store.setItem('controlWarningForm', {
			op: op,
			text: this.stringDeleteSubString(text, 'Error:'),
			callBack: callBack,
			cancleCallBack: cancleCallBack,
			showCancle: showCancle,
			okText: okText,
			cancleText: cancleText,
			title
		})
	},
	disorderArr(array) {
		for (let i = array.length - 1; i > 0; i--) {
			// 生成一个随机索引
			const j = Math.floor(Math.random() * (i + 1));
			// 交换元素
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
		// let re = []

		// function random(n) {
		// 	return parseInt(Math.random() * 1000) % n
		// }
		// while (arr.length) {
		// 	let n = random(arr.length)
		// 	re.push(arr[n])
		// 	arr = this.arrDeleteItemByIndex(arr, n)
		// }
		// return re
	},
	arrDeleteItemByIndex(arr, index) {
		let re = []
		for (let i = 0; i < arr.length; i++) {
			if (i == index) continue
			re.push(arr[i])
		}
		return re
	},
	sumArr(arr1, arr2) {
		for (let i = 0; i < arr2.length; i++) {
			arr1.push(arr2[i])
		}
		return JSON.parse(JSON.stringify(arr1))
	},
	coverTran: false,
	controlCoverForm(op = false, transition = false) {
		store.setItem('controlCoverForm', {
			op: op == true,
			transition: transition == true
		})
	},
	checkLoginStatus() {
		return store.getItem('userData') != undefined
	},
	requestURLPathNormalization(urlString) {
		urlString = urlString.split('')
		let re = [urlString[0]]
		for (let i = 1; i < urlString.length; i++) {
			if (urlString[i - 1] == '/' && urlString[i] == '/') continue
			re.push(urlString[i])
		}
		let res = ''
		for (let i = 0; i < re.length; i++) {
			res += re[i]
		}
		if (res.split(':')[0] == 'http') {
			if (res.indexOf('http:/') != -1 && res.indexOf('http://') == -1) {
				res = 'http://' + res.split('http:/')[1]
			}
		}
		if (res.split(':')[0] == 'https') {
			if (res.indexOf('https:/') != -1 && res.indexOf('https://') == -1) {
				res = 'https://' + res.split('https:/')[1]
			}
		}
		return res
	},
	localStorage: {
		setItem(key, value) {
			if (typeof (key) != 'string') {
				throw new Error('key应为String')
				return
			}
			if (typeof (value) != 'string') {
				throw new Error('value应为String')
				return
			}
			localStorage.setItem(encodeDecodePassword.encodeString(key), encodeDecodePassword.encodeString(value))
		},
		getItem(key) {
			if (typeof (key) != 'string') {
				throw new Error('key应为String')
				return
			}
			if (localStorage.getItem(encodeDecodePassword.encodeString(key)) == undefined) return undefined
			return encodeDecodePassword.decodeString(localStorage.getItem(encodeDecodePassword.encodeString(key)))
		},
		removeItem(key) {
			if (typeof (key) != 'string') {
				throw new Error('key应为String')
				return
			}
			localStorage.removeItem(encodeDecodePassword.encodeString(key))
		}
	},
	exitLogin(f = false) {
		if (f) {
			this.localStorage.removeItem('userData')
			location.reload()
		} else {
			this.controlWarningForm(true, '是否退出登录？此操作将清除当前' + tool.getCurrentEndPointName() + '的登录信息。', () => {
				this.localStorage.removeItem('userData')
				location.reload()
			})
		}
	},
	exitLoginWithoutQuestion() {
		this.localStorage.removeItem('userData')
		location.reload()
	},
	async handleShowDE(listType = '', exeType = '', id = undefined, sequentially = 0, list = undefined, vid, sid) {
		// console.log({
		// 	listType: listType,
		// 	exeType: exeType,
		// 	thirdId: id,
		// 	sequentially: sequentially,
		// 	questionList: list,
		// 	vehicleId: vid,
		// 	subjectId: sid
		// })
		store.setItem('allowShowDE', {
			listType: listType,
			exeType: exeType,
			thirdId: id,
			sequentially: sequentially,
			questionList: list,
			vehicleId: vid,
			subjectId: sid
		})
		router.push('/blank')
	},
	async handleShowRSE(listType = '', exeType = '', id = undefined, sequentially = 0, list = undefined, vid, sid) {
		store.setItem('allowShowRSE', {
			listType: listType,
			exeType: exeType,
			thirdId: id,
			sequentially: sequentially,
			questionList: list,
			vehicleId: vid,
			subjectId: sid
		})
		router.push('/blank')
	},
	async handleShowTSE(listType = '', exeType = '', id = undefined, sequentially = 0, list = undefined, vid, sid) {
		store.setItem('allowShowTSE', {
			listType: listType,
			exeType: exeType,
			thirdId: id,
			sequentially: sequentially,
			questionList: list,
			vehicleId: vid,
			subjectId: sid
		})
		router.push('/blank')
	},
	controlMenu3Form(op = false, secondId = '', type = '', tip = '', vid = undefined, sid = undefined) {
		store.setItem('controlMenu3Form', {
			op: op,
			secondId: secondId,
			type: type,
			tip: tip,
			vid: vid,
			sid: sid
		})
	},
	changeSecondsToTimeString(seconds) {
		let tc = seconds
		let h = 0
		let m = 0
		let s = 0
		tc = parseInt(tc / 1000)
		while (tc >= 3600) {
			h++
			tc -= 3600
		}
		while (tc >= 60) {
			m++
			tc -= 60
		}
		s = tc
		h = h.toString()
		m = m.toString()
		s = s.toString()
		if (m.length == 1) m = '0' + m
		if (s.length == 1) s = '0' + s
		return h + ':' + m + ':' + s
	},
	stringArrToString(stringArr) {
		let re = ''
		for (let i = 0; i < stringArr.length; i++) {
			if (i) re += '、'
			re += stringArr[i]
		}
		return re
	},
	controlChangePasswordForm(op) {
		store.setItem('controlChangePasswordForm', {
			op: op == true
		})
	},
	checkPassword(string) {
		string = string.split('')
		if (string.length < 6) {
			return {
				status: 0,
				content: '密码不能短于6位'
			}
		}
		let xx = false
		let dx = false
		let sz = false
		for (let i = 0; i < string.length; i++) {
			string[i] = string[i].charCodeAt(0)
			if (string[i] >= 65 && string[i] <= 90) {
				dx = true
			}
			if (string[i] >= 97 && string[i] <= 122) {
				xx = true
			}
			if (string[i] >= 48 && string[i] <= 57) {
				sz = true
			}
		}
		// if (xx == false) {
		// 	return {
		// 		status: 0,
		// 		content: '密码必须包含小写字母'
		// 	}
		// }
		// if (dx == false) {
		// 	return {
		// 		status: 0,
		// 		content: '密码必须包含大写字母'
		// 	}
		// }
		// if (sz == false) {
		// 	return {
		// 		status: 0,
		// 		content: '密码必须包含数字'
		// 	}
		// }
		return {
			status: 1
		}
	},
	controlChangeRegionForm(op, userType = 'student', callBack = undefined) {
		store.setItem('controlChangeRegionForm', {
			op: op == true,
			userType: userType,
			callBack: callBack
		})
	},
	async getRegionName(regionCode) {
		let re = await this.requestData('/get_region', {}, 'get')
		if (re.status) {
			re = re.data
			for (let i = 0; i < re.length; i++) {
				if (re[i].regionCode == regionCode) {
					return re[i].name
				}
			}
			return ''
		} else {
			return ''
		}
	},
	async getRegionCode(regionName) {
		let re = await this.requestData('/get_region', {}, 'get')
		if (re.status) {
			re = re.data
			for (let i = 0; i < re.length; i++) {
				if (re[i].name == regionName) {
					return re[i].regionCode
				}
			}
			return ''
		} else {
			return ''
		}
	},
	controlTipForm(op, question = undefined, isGF = false, img = undefined, fontZoom = 1) {
		store.setItem('controlTipForm', {
			op: op == true,
			question: question,
			isGF: isGF,
			img,
			fontZoom
		})
	},
	controlSelectDateForm(op, defaultDate = new Date(), callBack = () => { }) {
		store.setItem('controlSelectDateForm', {
			op: op == true,
			defaultDate,
			callBack
		})
	},
	controlBiaoLvForm(op, text = '', callBack = () => { }, subStrings = undefined) {
		store.setItem('controlBiaoLvForm', {
			op: op == true,
			text: text,
			callBack: callBack,
			subStrings
		})
	},
	controlLoading(op, text = '请稍候') {
		store.setItem('controlLoading', {
			op: op,
			text: text
		})
	},
	async getQuestionList(data) {
		let list = []
		if (data.listType == '地方专题') {
			let re = await this.requestData(tool.getHomeAPIUserType() + '/get_region_question_id_list', {
				regionCode: (await tool.getRegionCode(store.getItem('userData').regionName)),
				subjectId: data.subjectId
			})
			if (re.status) {
				list = re.data

				//判断题目列表是否为空
				if (re.data.length == 0) {
					throw new Error('题目列表为空')
				}

				if (data.sequentially == 1) {
					list = this.disorderArr(list)
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '三级类别') {
			let re = await this.requestData(tool.getHomeAPIUserType() + '/get_question_id_list', {
				thirdId: data.thirdId
			})
			if (re.status) {
				list = re.data

				//判断题目列表是否为空
				if (re.data.length == 0) {
					throw new Error('题目列表为空')
				}

				if (data.sequentially == 1) {
					list = this.disorderArr(list)
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '错题重做') {
			let re = await this.requestData(tool.getHomeAPIUserType() + '/get_wrong_list', {
				vehicleId: data.vehicleId,
				subjectId: data.subjectId
			})
			if (re.status) {
				list = re.data

				//判断题目列表是否为空
				if (re.data.length == 0) {
					throw new Error('题目列表为空')
				}

				if (data.sequentially == 1) {
					list = this.disorderArr(list)
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '易错题') {
			let re = await this.studentGetEasyToWrongList(data.vehicleId, data.subjectId)
			if (re.status == 1) {
				list = re.data
				if (list.length == 0) {
					throw new Error('题目列表为空')
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '模拟考试') {
			let re = await this.studentGetExamList(data.vehicleId, data.subjectId)
			if (re.status == 1) {
				list = re.data
				if (list.length == 0) {
					throw new Error('题目列表为空')
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '全部收藏') {
			let re = await this.requestData(tool.getHomeAPIUserType() + '/get_star_list', {
				vehicleId: data.vehicleId,
				subjectId: data.subjectId
			})
			if (re.status == 1) {
				list = re.data
				if (list.length == 0) {
					throw new Error('题目列表为空')
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '免费体验') {
			let re = await this.studentGetFreeList(data.vehicleId, data.subjectId)
			if (re.status == 1) {
				list = re.data
				if (list.length == 0) {
					throw new Error('题目列表为空')
				}
			} else {
				throw new Error(re.content)
			}
		}
		if (data.listType == '三力测试') {
			let re = await this.requestData(tool.getHomeAPIUserType() + (data.exeType == 'exam' ? '/san_li_simulation_exam' : '/get_san_li_list'), {
				// vehicleId: data.vehicleId,
				// subjectId: data.subjectId
			})
			if (re.status == 1) {
				list = re.data
				if (data.sequentially == 1) {
					list = this.disorderArr(list)
				}
				if (list.length == 0) {
					throw new Error('题目列表为空')
				}
			} else {
				throw new Error(re.content)
			}
		}
		for (let i = 0; i < list.length; i++) {
			if (list[i].questionId == undefined && list[i].id != undefined) {
				list[i].questionId = list[i].id
			}
		}
		if (Array.isArray(list) && list.length) return list
		throw new Error('无法获取试题列表')
	},
	stringDeleteSubString(string, subString) {
		string = string.split(subString)
		let re = ''
		for (let i = 0; i < string.length; i++) {
			re += string[i]
		}
		return re
	},
	loginFailed: {
		addRecord() {

		},
		clearRecord() {

		}
	},
	controlAudio(op = false, src, endCallBack = () => { }, readText = undefined, canEnd = true) {
		store.setItem('controlAudio', {
			op: op,
			src: src,
			endCallBack: endCallBack,
			readText,
			canEnd
		})
	},
	controlInput(op = false, input = '', placeHolder = '请输入', callBack = () => { }, textArea = false) {
		store.setItem('controlInputForm', {
			op: op,
			input: input,
			placeHolder: placeHolder,
			callBack: callBack,
			textArea: textArea
		})
	},
	controlMenuForm(op = false, title = '菜单', operationList = []) {
		for (let i = 0; i < operationList.length; i++) {
			if (operationList[i].name == undefined) {
				operationList[i].name = '未命名菜单项' + (i + 1).toString()
			}
			if (operationList[i].callBack == undefined) {
				operationList[i].callBack = () => { }
			}
		}
		store.setItem('controlMenuForm', {
			op: op,
			title: title,
			operationList: operationList
		})
	},
	selectFile(acceptedFormats) {
		return new Promise((resolve, reject) => {
			const input = document.createElement('input');
			input.type = 'file';
			input.accept = acceptedFormats; // e.g. '.jpg, .png, .pdf'
			input.multiple = false; // Limit to only one file

			input.onchange = (event) => {
				const file = event.target.files[0];
				if (file) {
					resolve(file);
				} else {
					reject(new Error('No file selected'));
				}
			};

			input.click(); // Trigger the file dialog
		});
	},
	formatTimeNew(inputTime = new Date()) {
		function getPeriod(hours) {
			if (hours >= 0 && hours < 6) {
				return '凌晨';
			} else if (hours >= 6 && hours < 11) {
				return '上午';
			} else if (hours >= 11 && hours < 14) {
				return '中午';
			} else if (hours >= 14 && hours < 18) {
				return '下午';
			} else {
				return '晚上';
			}
		}

		function getWeekName(n) {
			switch (n) {
				case 0:
					return '星期天'
				case 1:
					return '星期一'
				case 2:
					return '星期二'
				case 3:
					return '星期三'
				case 4:
					return '星期四'
				case 5:
					return '星期五'
				case 6:
					return '星期六'
			}
		}
		let currentTime = new Date()
		let oneDaySecond = 86400000
		let currentDayStart = new Date(currentTime.getTime() - currentTime.getHours() * 60 * 60 * 1000 - currentTime
			.getMinutes() * 60 * 1000 - currentTime.getSeconds() * 1000)
		let lastDayStart = new Date(currentTime.getTime() - currentTime.getHours() * 60 * 60 * 1000 - currentTime
			.getMinutes() * 60 * 1000 - currentTime.getSeconds() * 1000 - oneDaySecond)
		let nextDayStart = new Date(currentTime.getTime() - currentTime.getHours() * 60 * 60 * 1000 - currentTime
			.getMinutes() * 60 * 1000 - currentTime.getSeconds() * 1000 + oneDaySecond)
		let nextDayEnd = new Date(currentTime.getTime() - currentTime.getHours() * 60 * 60 * 1000 - currentTime
			.getMinutes() * 60 * 1000 - currentTime.getSeconds() * 1000 + oneDaySecond * 2)
		let prefix = ''
		if (inputTime >= currentDayStart && inputTime < nextDayStart) {
			prefix = '今天'
		}
		if (inputTime >= lastDayStart && inputTime < currentDayStart) {
			prefix = '昨天'
		}
		if (inputTime >= nextDayStart && inputTime < nextDayEnd) {
			prefix = '明天'
		}
		if (prefix == '') {
			prefix = (inputTime.getFullYear() == currentTime.getFullYear() ? '' : inputTime.getFullYear().toString() +
				'年') + (inputTime.getMonth() + 1).toString() + '月' + inputTime.getDate() + '日 '
		}
		prefix += getPeriod(inputTime.getHours()) + ' '
		prefix += inputTime.getHours().toString() + ':'
		if (inputTime.getMinutes() < 10) {
			prefix += '0'
		}
		prefix += inputTime.getMinutes().toString() + ' '
		prefix += getWeekName(inputTime.getDay())
		return prefix
	},
	pageZoom() {
		return store.getItem('pageZoom') == undefined ? 1 : store.getItem('pageZoom')
	},
	controlThirdListForm(op = false, type = 1, callBack = () => { }) {
		store.setItem('controlThirdListForm', {
			op: op,
			type: type,
			callBack: callBack
		})
	},
	controlSetQuestionIndexForm(op = false, thirdList = [], questionList = [], callBack = undefined) {
		store.setItem('controlSetQuestionIndexForm', {
			op: op,
			thirdList: thirdList,
			questionList: questionList,
			callBack: callBack
		})
	},
	isLogined() {
		return store.getItem('userData') != undefined
	},
	getMainPageSelectedIds() {
		return store.getItem('mainPageSelectedIds')
	},
	handleAdminChangePage(name1 = undefined, name2 = undefined, data = {}, fromPage = undefined) {
		store.setItem('adminChangePage', {
			name1: name1,
			name2: name2,
			data: {
				...data,
				fromPage: fromPage
			}
		})
	},
	getFileFormData(file) {
		const formData = new FormData();
		formData.append('file', file);
		return formData
	},
	controlSelectRegionForm(op = false, callBack = () => { }) {
		store.setItem('controlSelectRegionForm', {
			op: op,
			callBack: callBack
		})
	},
	async studentGetEasyToWrongList(vid, sid) {
		let re = await tool.requestData(tool.getHomeAPIUserType() + '/get_easily_wrong_list', {
			"vehicleId": vid,
			"subjectId": sid
		})
		return re
	},
	async studentGetFreeList(vid, sid) {
		let re = await tool.requestData(tool.getHomeAPIUserType() + '/get_free_try_list', {
			"vehicleId": vid,
			"subjectId": sid
		})
		return re
	},
	async studentGetExamList(vid, sid) {
		let re = await tool.requestData(tool.getHomeAPIUserType() + '/simulation_exam', {
			"vehicleId": vid,
			"subjectId": sid
		})
		return re
	},
	getImageContent(url) {
		return new Promise(async (resolve, reject) => {
			try {
				return resolve((await axios.get(url)).data)
			} catch (error) {
				// 11 // 11_2 // console.log(error)
				return resolve(undefined)
			}
		})
	},
	getHomeAPIUserType() {
		let t = store.getItem('userType')
		if (t == undefined) return '/student'
		return '/' + t
	},
	getCurrentURL() {
		let t = location.href.split('')
		let re = ''
		let c = 0
		for (let i = 0; i < t.length; i++) {
			if (t[i] == '/') c++
			if (c == 3) return re
			re += t[i]
		}
		return re
	},
	handleShowUploadPage(id) {
		tool.controlMenuForm(true, '操作', [
			{
				name: '电脑端操作',
				callBack: () => {
					let s = ''
					let c = 0
					s += '/uploadExplanatorySpeech?d=' + encodeDecodePassword.encodeString(JSON.stringify({
						questionId: id,
						time: new Date()
					}))

					// let t = this.getCurrentURL()
					// t += '/autoSkip?d=' + encodeDecodePassword.encodeString(JSON.stringify({
					// 	...(store.getItem('userNameAndPassword') == undefined ? {} : store.getItem('userNameAndPassword')),
					// 	time: new Date(),
					// 	path: s,
					// 	satoken: store.getItem("userData_satoken"),
					// 	userType: 'coach',
					// 	data: {
					// 		key: '',
					// 		value: ''
					// 	}
					// }))

					tool.framePostMessageToParent({
						op: 'frameOpenPage',
						data: {
							name1: '个性语音',
							data: {
								path: s
							}
						}
					})

					// tool.openPopup(t, '_blank')

					// tool.controlWarningForm(true, '<iframe style="width:100%;height:calc(560px);border:0" frameborder="no" border="0" src="' + t + '"></iframe>', () => {

					// }, () => { }, false, '关闭窗口')
				}
			},
			{
				name: '移动端操作',
				callBack: async () => {
					let s = ''
					let c = 0
					s += '/uploadExplanatorySpeech?d=' + encodeDecodePassword.encodeString(JSON.stringify({
						questionId: id,
						time: new Date()
					}))
					async function generateQRCodeBase64(text) {
						const QRCode = require('qrcode')
						try {
							// 使用 qrcode.toDataURL 生成 Base64 格式的二维码
							const base64QRCode = await QRCode.toDataURL(text, {
								errorCorrectionLevel: 'L',
								width: 100
							});
							return base64QRCode;
						} catch (err) {
							// 11_2 console.error(err);
						}
					}

					let t = this.getCurrentURL()
					t += '/autoSkip?d=' + encodeDecodePassword.encodeString(JSON.stringify({
						...(store.getItem('userNameAndPassword') == undefined ? {} : store.getItem('userNameAndPassword')),
						time: new Date(),
						path: s,
						satoken: store.getItem("userData_satoken"),
						userType: 'coach',
						data: {
							key: '',
							value: ''
						}
					}))

					this.controlWarningForm(true, '<div style="text-align:center"><img style="width:380px;height:380px;margin:0 auto;display:inline-block" src="' + (await generateQRCodeBase64(t)) + '"></img></div>', () => {

					}, () => {

					})
				}
			}
		])
	},
	async blobUrlToFormData(blobUrl, extension = '') {
		let fileName = 'file'
		try {
			// 使用 fetch 从 Blob URL 获取 Blob 对象
			const response = await fetch(blobUrl)
			const blob = await response.blob() // 获取 Blob 对象

			// 如果文件名已经带有扩展名，则不追加扩展名
			// 否则，使用传入的 extension 作为扩展名
			if (!fileName.includes('.') && extension) {
				fileName += `.${extension}`
			}

			// 创建 FormData 并将 Blob 添加到其中
			const formData = new FormData()
			formData.append('file', blob, fileName) // 默认文件名为 'file' 或者带有指定扩展名

			return formData // 返回 FormData 对象
		} catch (error) {
			// 11_2 console.error('Error while converting Blob URL to FormData:', error)
			throw error
		}
	},
	validatePhoneNumber(phone) {
		const regex = /^1[3-9]\d{9}$/; // 匹配以1开头，第二位为3-9之间的数字，后面跟随9个数字
		return regex.test(phone);
	},
	stringArrSortByLength(arr = []) {
		return arr.sort((a, b) => {
			return b.length - a.length
		})
	},
	formatNumberToLength(n, length) {
		if (length < n.length) return n.toString()
		n = n.toString()
		length -= n.length
		for (let i = 0; i < length; i++) {
			n = '0' + n
		}
		return n
	},
	splitHighLightArr(arr, keyword = '') {

		function highlightKeywords(sentenceArray, keyword) {
			// 检查关键词是否在句子中
			const sentence = sentenceArray.map(obj => obj.text).join('');
			if (!sentence.includes(keyword)) {
				return sentenceArray; // 如果关键词不在句子中，直接返回原数组
			}

			let startIndex = 0;
			// 遍历数组，找到关键词的每个字并标注
			for (let i = 0; i < sentenceArray.length; i++) {
				// 检查当前索引是否是关键词的起始位置
				if (sentence.slice(i, i + keyword.length) === keyword) {
					for (let j = 0; j < keyword.length; j++) {
						sentenceArray[i + j].xt = true; // 标注关键词中的每个字
					}
					startIndex = i + keyword.length; // 更新下一次搜索的起始位置
				}
			}
			return sentenceArray;
		}

		let re = []
		for (let i = 0; i < arr.length; i++) {
			arr[i].text = arr[i].text.split('')
			for (let i1 = 0; i1 < arr[i].text.length; i1++) {
				re.push({
					text: arr[i].text[i1],
					flag: arr[i].flag
				})
			}
		}

		if (keyword) {
			re = highlightKeywords(re, keyword)
		}

		return re
	},
	getHighlightedTextHTML(string, subStrings) {
		if (subStrings == undefined) subStrings = []
		let sbust = []
		for (let i = 0; i < subStrings.length; i++) {
			if (subStrings[i] == null) continue
			sbust.push(parseInt(subStrings[i]))
		}
		subStrings = sbust
		let re1 = []
		string = string.split('')
		for (let i = 0; i < string.length; i++) {
			if (re1.length == 0) {
				re1.push({
					flag: subStrings.indexOf(i) == -1 ? false : true,
					text: string[i]
				})
			} else {
				if (((subStrings.indexOf(i - 1) == -1 && subStrings.indexOf(i) == -1) || (subStrings.indexOf(i - 1) != -1 && subStrings.indexOf(i) != -1)) == false) {
					re1.push({
						flag: subStrings.indexOf(i) == -1 ? false : true,
						text: string[i]
					})
				} else {
					re1[re1.length - 1].text += string[i]
				}
			}
		}
		return re1
		function sumArr(arr1, arr2) {
			for (let i = 0; i < arr2.length; i++) {
				arr1.push(arr2[i])
			}
			return JSON.parse(JSON.stringify(arr1))
		}
		function splitString(string, word) {
			let re = []
			string = string.split(word)
			for (let i = 0; i < string.length; i++) {
				if (i) {
					re.push({
						flag: true,
						text: word
					})
				}
				re.push({
					flag: false,
					text: string[i]
				})
			}
			return re
		}

		let re = string
		re = [
			{
				flag: false,
				text: re
			}
		]
		if (!(subStrings.length == 1 && subStrings[0] == '')) {
			for (let i = 0; i < subStrings.length; i++) {
				for (let i1 = 0; i1 < re.length; i1++) {
					if (re[i1].flag == true) continue
					re = sumArr(sumArr(re.slice(0, i1), splitString(re[i1].text, subStrings[i])), re.slice(i1 + 1))
				}
			}
		}
		return re
	},
	getXuanXiangBiaoLv(string, subStrings, color, defaultColor = 'rgb(46, 66, 50)') {
		if (subStrings == undefined || subStrings == null) subStrings = []
		let re = this.getHighlightedTextHTML(string, subStrings)
		let res = ''
		for (let i = 0; i < re.length; i++) {
			if (re[i].flag) {
				res += `<span style="font-size: inherit;font-family: 微软雅黑;color: ${color};user-select:text;">${re[i].text}</span>`
			} else {
				res += `<span style="font-size: inherit;font-family: 微软雅黑;color: ${defaultColor};user-select:text;">${re[i].text}</span>`
			}
		}
		return res
	},
	showAboutUS(string) {
		let re = '<div style="max-width: 600px;width: 100%;max-height: 80vh;height: 100%;">' + string + '</div>'
		this.controlWarningForm(2, re, () => { }, undefined, false, '确定', '', '关于我们')
	},
	async handleGetAndShowAboutUS() {
		let re = await this.requestData('/about_us', {}, 'post')
		if (re.status == 1) {
			this.showAboutUS(re.data)
		} else {
			tool.controlWarningForm(2, '无法获取关于我们内容。')
		}
	},
	showContactUS(string) {
		let re = '<div style="max-width: 600px;width: 100%;max-height: 80vh;height: 100%;">' + string + '</div>'
		this.controlWarningForm(2, re, () => { }, undefined, false, '确定', '', '联系我们')
	},
	async handleGetAndShowContactUS() {
		let re = await this.requestData('/contact_us', {}, 'post')
		if (re.status == 1) {
			this.showContactUS(re.data)
		} else {
			tool.controlWarningForm(2, '无法获取联系我们内容。')
		}
	},
	setting: {
		showUI() {
			let menu = []

			// 动画效果


			tool.controlMenuForm(1, '设置', menu)
		}
	},
	getSingleQuestionPreviewData(item, keyword = '') {
		item = JSON.parse(JSON.stringify(item))
		if (!item.biaohong) item.biaohong = ''
		item.biaohong = item.biaohong.split(',')
		for (let i = 0; i < item.biaohong.length; i++) {
			item.biaohong[i] = parseInt(item.biaohong[i])
		}


		if (!item.jiqiaobiaohong) item.jiqiaobiaohong = ''
		item.jiqiaobiaohong = item.jiqiaobiaohong.split(',')
		for (let i = 0; i < item.jiqiaobiaohong.length; i++) {
			item.jiqiaobiaohong[i] = parseInt(item.jiqiaobiaohong[i])
		}

		if (!item.biaolv) item.biaolv = ''
		item.biaolv = item.biaolv.split('=')
		for (let i = 0; i < item.biaolv.length; i++) {
			item.biaolv[i] = item.biaolv[i].split(',')
			for (let i1 = 0; i1 < item.biaolv[i].length; i1++) {
				item.biaolv[i][i1] = parseInt(item.biaolv[i][i1])
			}
		}

		if (!item.xuanxiang) item.xuanxiang = ''
		item.xuanxiang = item.xuanxiang.split('-')


		if (!item.daan) item.daan = ''
		item.daan = item.daan.split('-')

		item.tigan = this.splitHighLightArr(this.getHighlightedTextHTML(item.tigan, item.biaohong), keyword)
		item.jiqiaojieshi = this.getHighlightedTextHTML(item.jiqiaojieshi, item.jiqiaobiaohong)
		for (let i = 0; i < item.xuanxiang.length; i++) {
			item.xuanxiang[i] = {
				parsed: this.splitHighLightArr(this.getHighlightedTextHTML(item.xuanxiang[i], item.biaolv[i]), keyword),
				value: item.xuanxiang[i]
			}
		}

		let re = {
			tigan: '',
			xuanxiang: '',
			jiqiaojieshi: ''
		}

		if (item.yicuoti) {
			re.tigan += `<span
                                style="font-size: 14px;padding: 0px 2px;border-radius: 5px;color: red;background-color: rgba(255,0,0,0.1);margin-right: 5px;font-family: 微软雅黑;">易错</span>`
		}

		if (item.mianfeitiyan) {
			re.tigan += `<span
                                style="font-size: 14px;padding: 0px 2px;border-radius: 5px;color: #24c27d;background-color: rgba(0,255,0,0.1);margin-right: 5px;font-family: 微软雅黑;">免费</span>`
		}

		for (let i = 0; i < item.tigan.length; i++) {
			if (item.tigan[i].flag) {
				re.tigan += `<span style="font-size: 16px;color: red;font-family: 微软雅黑;user-select:text;font-style: ${item.tigan[i].xt ? 'italic' : 'unset'};font-weight: ${item.tigan[i].xt ? 'bold' : 'unset'};">${item.tigan[i].text}</span>`
			} else {
				re.tigan += `<span style="font-size: 16px;color: rgb(46, 66, 50);font-family: 微软雅黑;user-select:text;font-style: ${item.tigan[i].xt ? 'italic' : 'unset'};font-weight: ${item.tigan[i].xt ? 'bold' : 'unset'};">${item.tigan[i].text}</span>`
			}
		}

		for (let i = 0; i < item.jiqiaojieshi.length; i++) {
			if (item.jiqiaojieshi[i].flag) {
				re.jiqiaojieshi += `<span style="font-size: 14px;color: red;font-family: 微软雅黑;user-select:text;">${item.jiqiaojieshi[i].text}</span>`
			} else {
				re.jiqiaojieshi += `<span style="font-size: 14px;color: rgb(0, 120, 215);font-family: 微软雅黑;user-select:text;">${item.jiqiaojieshi[i].text}</span>`
			}
		}

		let prefixs = ['A', 'B', 'C', 'D']

		for (let i = 0; i < item.xuanxiang.length; i++) {
			re.xuanxiang += `<span style="margin-right: 8px;border: ${item.daan.indexOf(item.xuanxiang[i].value) == -1 ? 'unset' : '1px solid gray'};background-color: ${item.daan.indexOf(item.xuanxiang[i].value) == -1 ? 'unset' : 'yellow'};">${prefixs[i] + '. '}`
			for (let i1 = 0; i1 < item.xuanxiang[i].parsed.length; i1++) {
				if (item.xuanxiang[i].parsed[i1].flag) {
					re.xuanxiang += `<span style="font-size: 14px;font-family: 微软雅黑;color: rgb(36, 194, 125);user-select:text;font-style: ${item.xuanxiang[i].parsed[i1].xt ? 'italic' : 'unset'};font-weight: ${item.xuanxiang[i].parsed[i1].xt ? 'bold' : 'unset'};">${item.xuanxiang[i].parsed[i1].text}</span>`
				} else {
					re.xuanxiang += `<span style="font-size: 14px;font-family: 微软雅黑;color: rgb(46, 66, 50);user-select:text;font-style: ${item.xuanxiang[i].parsed[i1].xt ? 'italic' : 'unset'};font-weight: ${item.xuanxiang[i].parsed[i1].xt ? 'bold' : 'unset'};">${item.xuanxiang[i].parsed[i1].text}</span>`
				}
			}
			re.xuanxiang += '</span>'
		}

		return re
	},
	async isFileSizeExceeding(blobUrl) {
		try {
			const response = await fetch(blobUrl);
			const blob = await response.blob();
			const fileSizeInMB = blob.size / (1024 * 1024); // 转换为 MB

			return fileSizeInMB <= 10; // 如果小于或等于10MB返回true，否则返回false
		} catch (error) {
			// 11_2 console.error('Error fetching the blob URL:', error);
			return false; // 出现错误时默认返回false
		}
	},
	parseIntStringArr_and_delete_NaN_undefined_Null(stringArr) {
		let re = []
		for (let i = 0; i < stringArr.length; i++) {
			stringArr[i] = parseInt(stringArr[i])
			if (isNaN(stringArr[i]) == true) continue
			re.push(parseInt(stringArr[i]))
		}
		return re
	},
	previewImg(url) {
		this.controlWarningForm(1, `<img style="width: 100%;" src="${url}"></img>`, () => { }, undefined, false)
	},
	controlImagePreviewForm(op = false, url = '') {
		if (!url && op) return
		store.setItem('controlImagePreviewForm', {
			op: op,
			url
		})
	},
	adminShowPage() {
		store.setItem('adminShowPage', true)
	},
	coachShowPage() {
		store.setItem('coachShowPage', true)
	},
	async exportThreeToExcel(thirdId) {
		let re = await tool.requestData('/admin/output_third_question_list_to_excel', {
			thirdId
		})
		if (re.status == 1) {
			re = re.data.url
			window.open(re, '_blank')
		} else {
			tool.controlWarningForm(1, re.content)
		}
	},
	handleChangePage(total = 0, callBack = () => { }) {
		tool.controlInput(1, '', '请输入页码', (e) => {
			e = parseInt(e)
			if (isNaN(e)) {
				tool.controlWarningForm(1, '页码应为正整数')
				return
			}
			if (e < 1 || e > total) {
				tool.controlWarningForm(1, `页码应为正整数且不大于${total}`)
				return
			}
			try {
				callBack(e)
			} catch (error) {

			}
		})
	},
	getStringFirstDiffIndex(str1, str2) {
		str1 = str1.split('')
		str2 = str2.split('')
		for (let i = 0; i < Math.max(str1.length, str2.length); i++) {
			if (str1[i] != str2[i]) return i
		}
		return -1
	},
	arrDeleteFirstItemByItem(arr, item) {
		return this.arrDeleteItemByIndex(arr, arr.indexOf(item))
	},
	getStringFirstDiffIndexes(str1, str2) {
		let arr = [str1, str2].sort((a, b) => {
			return a.length - b.length
		})
		str1 = arr[0]
		str2 = arr[1]
		str1 = str1.split('')
		str2 = str2.split('')
		let re = []
		let index = 0
		let c = 0
		while (1) {
			if (str1[index] == str2[index + c]) {
				index++
				if (index == str2.length) break
			} else {
				re.push(index + c)
				c++
			}
		}
		return re
	},
	adminScrollToTop() {
		store.setItem('adminScrollToTop', true)
	},
	captureDivAsBase64(divId) {
		return new Promise((resolve, reject) => {
			const targetDiv = document.getElementById(divId);
			if (!targetDiv) {
				// 11_2 console.error("Element not found!");
				return;
			}

			// 使用 HTML2Canvas 将目标 div 转换为截图
			html2canvas(targetDiv)
				.then(canvas => {
					// 将 Canvas 转换为 Base64
					const base64Image = canvas.toDataURL("image/png");
					// 11_2 // console.log(base64Image); // 这里是 Base64 字符串
					return resolve(base64Image)
				})
				.catch(error => {
					// 11_2 console.error("Error capturing screenshot:", error);
				});
		})
	},
	watchResize(id, callBack) {
		const myDiv = document.getElementById(id)
		const resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				const { width, height } = entry.contentRect;
				try {
					callBack({
						width: width,
						height: height
					})
				} catch (error) {

				}
			}
		})
		resizeObserver.observe(myDiv)
	},
	handlePostMessageToWebview(message) {
		try {
			window.cefSharp.postMessage(message)

		} catch (error) {

			// 11_2 // console.log(error)
		}
		try {
			window.electron.send(message)
		} catch (error) {

		}
	},
	isCefSharp() {
		return tool.localStorage.getItem('isApp') == 'true'
	},
	isCefSharpAdmin() {
		return tool.localStorage.getItem('isAppAdmin') == 'true'
	},
	getCurrentEndPointName() {
		return this.isCefSharp() ? '客户端' : '浏览器'
	},
	handleWindowClose() {
		window.close()
	},
	openPopup(url) {
		window.open(url, 'popupWindow', 'width=1200,height=800,scrollbars=yes,resizable=yes');
	},
	framePostMessageToParent(data) {
		window.parent.postMessage(JSON.stringify(data))
	},
	controlSanLiStartForm(op, title) {
		store.setItem('controlSanLiStartForm', {
			op,
			title
		})
	},
	controlExamStartForm(op, title, callBack1 = () => { }, callBack2 = () => { }) {
		store.setItem('controlExamStartForm', {
			op,
			title,
			callBack1,
			callBack2
		})
	},
	controlExamEndForm(op, data) {
		store.setItem('controlExamEndForm', {
			op,
			data
		})
	},
	isFrame() {
		return store.getItem('isFrame') == true
	},
	getAllSelectedText() {
		let selectedText = '';

		// 获取页面上选中的文本
		const selection = window.getSelection();
		if (selection.rangeCount > 0) {
			selectedText = selection.toString();
		}

		// 检查输入框和富文本框
		const inputElements = document.querySelectorAll('input[type="text"], textarea, [contenteditable="true"]');

		inputElements.forEach(input => {
			const inputSelection = window.getSelection();
			if (inputSelection.rangeCount > 0) {
				const range = inputSelection.getRangeAt(0);
				const inputText = range.toString();
				if (inputText) {
					selectedText += (selectedText ? '\n' : '') + inputText;
				}
			}
		});

		return selectedText;
	},
	sumString(arr, keyName, split = '-') {
		let addSplit = false
		let re = ''
		for (let i = 0; i < arr.length; i++) {
			if (keyName == undefined) {
				if (arr[i] == null || (isNaN(arr[i]) && !typeof (arr[i]) == 'string')) continue
				if (addSplit) {
					re += split
				} else {
					addSplit = true
				}
				re += arr[i].toString()
			} else {
				if (arr[i][keyName] == null || (isNaN(arr[i][keyName]) && !typeof (arr[i][keyName]) == 'string')) continue
				if (addSplit) {
					re += split
				} else {
					addSplit = true
				}
				re += arr[i][keyName].toString()
			}
		}
		return re
	},
	replaceCharacter(str, targetChar, newChar) {
		// 创建一个正则表达式来全局匹配目标字符
		const regex = new RegExp(targetChar, 'g');
		// 使用 replace 方法替换字符
		return str.replace(regex, newChar);
	},
	getDistanceToParent(childId, parentId) {
		const child = document.getElementById(childId);
		const parent = document.getElementById(parentId);

		const childRect = child.getBoundingClientRect();
		const parentRect = parent.getBoundingClientRect();

		const distanceTop = childRect.top - parentRect.top;
		const distanceLeft = childRect.left - parentRect.left;

		return {
			top: distanceTop,
			left: distanceLeft
		};
	},
	decodeQRCode(imageUrl) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = imageUrl;

			img.onload = () => {
				// 创建 canvas 元素并绘制图片
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0, img.width, img.height);

				// 获取图像数据
				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

				try {
					// 使用 qecode 解码二维码
					const result = qrcode.decode(imageData.data, canvas.width, canvas.height);
					if (result) {
						resolve(true); // 如果解码成功，返回 true
					} else {
						resolve(false); // 如果解码失败，返回 false
					}
				} catch (error) {
					reject(error); // 如果有异常，拒绝 Promise
				}
			};

			img.onerror = () => {
				reject('Image loading failed');
			};
		});
	},
	async processImage(url) {
		// 创建一个图片元素
		const img = new Image();
		img.crossOrigin = "Anonymous";  // 处理跨域问题，如果图片来自不同域名
		img.src = url;

		// 等待图片加载完成
		await new Promise((resolve, reject) => {
			img.onload = resolve;
			img.onerror = reject;
		});

		// 创建一个 canvas 元素来绘制和处理图片
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		// 扩展边界：为了让模糊效果不只局限于图片中心，我们将canvas大小稍微增大
		const borderSize = 200;  // 扩展边界的大小，确保模糊效果能覆盖整个图像
		const width = img.width + borderSize * 2;
		const height = img.height + borderSize * 2;

		// 设置 canvas 的宽度和高度
		canvas.width = width;
		canvas.height = height;

		// 绘制扩展后的背景（清晰的原图）
		ctx.drawImage(img, borderSize, borderSize);

		// 应用高斯模糊，模糊整个画布
		ctx.filter = "blur(200px)";  // 使用200px模糊强度
		ctx.drawImage(canvas, 0, 0); // 这里将原图上已经应用模糊的内容再绘制一次到 canvas 上

		// 创建一个新的 canvas 来恢复原图的尺寸
		const finalCanvas = document.createElement("canvas");
		const finalCtx = finalCanvas.getContext("2d");

		// 设置最终 canvas 的尺寸为原图尺寸
		finalCanvas.width = img.width;
		finalCanvas.height = img.height;

		// 将模糊后的图像裁剪为原图尺寸
		finalCtx.drawImage(canvas, borderSize, borderSize, img.width, img.height, 0, 0, img.width, img.height);

		// 获取处理后的图片的 base64 编码
		const base64 = finalCanvas.toDataURL("image/png");

		return base64;
	},
	controlRSEForm(op = false, index = 0, question = {}) {
		store.setItem('controlRSEForm', {
			op: op == true,
			index,
			question
		})
		// console.log(question)
	},
	thisIsError(index, currentIndex) {
		return index === currentIndex
	},
	async getFrontendSettings() {
		let re = await tool.requestData('/get_frontend_settings')
		if (re.status) {
			let t = re.data.frontendSettings
			try {
				t = JSON.parse(t)
				store.setItem('frontendSettings', t)
			} catch (error) {

			}
		}
	},
	async updateFrontendSettings() {
		let re = await tool.requestData('/admin/update_frontend_settings', {
			"frontendSettings": JSON.stringify(store.getItem('frontendSettings'))
		})
		if (re.status) {
			return true
		}
		return false
	},
	deleteBiaoHongNaN(str) {
		if (str == null) return ''
		const formatStr = (s) => {
			s = this.stringDeleteSubString(s, 'NaN')
			let re = []
			s = s.split('')
			if (s[0] == ',') s = s.slice(1)
			if (s[s.length - 1] == ',') s = s.slice(0, s.length - 1)
			for (let i = 1; i < s.length; i++) {
				if (s[i - 1] == ',' && s[i] == ',') s[i - 1] = undefined
			}
			for (let i = 0; i < s.length; i++) {
				if (s[i] !== undefined) {
					re.push(s[i])
				}
			}
			let res = ''
			for (let i = 0; i < re.length; i++) {
				res += re[i]
			}
			return res
		}
		str = str.split('=')
		for (let i = 0; i < str.length; i++) {
			str[i] = formatStr(str[i])
		}
		let res = ''
		for (let i = 0; i < str.length; i++) {
			if (i) res += '='
			res += str[i]
		}
		return res
	},
	deleteQuestionBiaoHongNaN(item) {
		item.biaohong = this.deleteBiaoHongNaN(item.biaohong)
		item.jiqiaobiaohong = this.deleteBiaoHongNaN(item.jiqiaobiaohong)
		item.biaolv = this.deleteBiaoHongNaN(item.biaolv)
		return item
	},
	windowOpen(url) {
		window.open(url, '_blank')
	}
}