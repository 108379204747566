<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
        }">
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: tool.pageZoom()
        }"
            style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-width: calc(100vw - 40px);max-height: calc(100vh - 40px);width: 600px;height: auto;overflow-y: auto;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
            <div style="width: 100%;height: 24px;">
                <div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
                    选项标注</div>
                <div style="display: inline-block;height: 100%;float: right;">
                    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
                        <svg @click="() => {
                            config.control(false)
                        }" v-if="config.callBack != undefined && config.showCancle == true" style="float: right;"
                            width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                style="width: 100%;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;margin-top: 16px;">
                <div style="font-family: 微软雅黑;color: gray;font-size: 14px;">
                    涂抹要标注的文字
                </div>
                <div @mouseup="config.biaolv.handleStop()" @mouseleave="config.biaolv.handleStop()"
                    style="width: 100%;padding: 16px;border-radius: 8px;background-color: #f6f8f7;line-height: 2;word-break: break-all;">
                    <span @mouseenter="config.biaolv.handleMouseEnter(index)"
                        @mousedown="config.biaolv.handleMouseDown(index)"
                        v-for="(item, index) in config.biaolv.display()"
                        style="padding: 2px 5px;border-radius: 3px;background-color: rgba(0,0,0,0.1);font-size: 18px;font-family: 微软雅黑 ;margin: 0 2px;"
                        :style="{
                            backgroundColor: item.backgroundColor,
                            color: item.color,
                        }">{{
                            item.text }}</span>
                </div>
            </div>
            <div
                style="width: 100%;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;margin-top: 16px;">
                <div style="font-family: 微软雅黑;color: gray;font-size: 14px;">
                    预览
                </div>
                <div
                    style="width: 100%;padding: 16px;border-radius: 8px;background-color: #f6f8f7;line-height: 2;word-break: break-all;font-size: 22px;">
                    <span style="font-family: 微软雅黑;" v-for="(item, index) in config.biaolv.getPreview()" :style="{
                        color: item.flag ? (config.biaolv.previewColor) : 'black',
                        // fontWeight: (item.flag) ? 'bold' : 'unset'
                    }">{{ item.text }}</span>
                </div>
            </div>
            <div
                style="width: 100%;margin-top: 36px;display: flex;align-items: center;justify-content: center;gap: 8px;">
                <yButton color="#2e4232" style="width: 100%;" @click="config.control(false)">取消</yButton>
                <yButton color="white" background-color="#24c27d" mask-color="white" class="activeScale" @click="() => {
                    config.handleOKClick()
                }" style="width: 100%;">确定</yButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const config = ref({
    show: 0,
    text: '',
    callBack: () => { },
    async control(op) {
        if (op) {
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    },
    biaolv: {
        selected: [],
        display() {
            let t = config.value.text.split('')
            for (let i = 0; i < t.length; i++) {
                t[i] = {
                    text: t[i],
                    backgroundColor: this.getTempSelected().indexOf(i) > -1 ? 'rgb(0,120,215)' : (this.selected.indexOf(i) > -1 ? 'rgba(255,0,0,0.1)' : 'rgba(0,0,0,0.1)'),
                    color: this.getTempSelected().indexOf(i) > -1 ? 'white' : (this.selected.indexOf(i) > -1 ? 'red' : '#2e4232'),
                }
            }
            return t
        },
        areaDown: 0,
        areaCurrent: 0,
        drawing: false,
        handleMouseDown(index) {
            this.drawing = true
            this.areaCurrent = index
            this.areaDown = index
        },
        handleMouseEnter(index) {
            if (this.drawing == false) return
            this.areaCurrent = index
        },
        handleStop() {
            if (this.drawing == false) return []
            let t = this.getTempSelected()
            let tt = this.selected
            for (let i = 0; i < t.length; i++) {
                if (tt.indexOf(t[i]) == -1) {
                    tt.push(t[i])
                } else {
                    tt = tool.arrDeleteItemByIndex(tt, tt.indexOf(t[i]))
                }
            }
            this.drawing = false
            this.selected = tt
        },
        getTempSelected() {
            if (this.drawing == false) return []
            let area = [this.areaDown, this.areaCurrent]
            area.sort()
            let re = []
            for (let i = area[0]; i <= area[1]; i++) {
                re.push(i)
            }
            return re
        },
        previewColor: '#2e4232',
        getBiaoLv() {
            return this.selected
            let re = ['']
            let t = JSON.parse(JSON.stringify(this.selected))
            t = t.sort((a, b) => { return a - b })
            for (let i = 0; i < t.length; i++) {
                if (i == 0 || (t[i - 1] + 1 == t[i])) {
                    re[re.length - 1] += config.value.text.slice(t[i], t[i] + 1)
                } else {
                    re.push(config.value.text.slice(t[i], t[i] + 1))
                }
            }
            re = tool.stringArrSortByLength(re)
            return re
        },
        getPreview() {
            return tool.getHighlightedTextHTML(config.value.text, this.getBiaoLv())
            function splitString(string, word) {
                let re = []
                string = string.split(word)
                for (let i = 0; i < string.length; i++) {
                    if (i) {
                        re.push({
                            flag: true,
                            text: word
                        })
                    }
                    re.push({
                        flag: false,
                        text: string[i]
                    })
                }
                return re
            }
            let re = config.value.text
            re = [
                {
                    flag: false,
                    text: re
                }
            ]
            if (!(this.getBiaoLv().length == 1 && this.getBiaoLv()[0] == '')) {
                for (let i = 0; i < this.getBiaoLv().length; i++) {
                    for (let i1 = 0; i1 < re.length; i1++) {
                        if (re[i1].flag == true) continue
                        re = tool.sumArr(tool.sumArr(re.slice(0, i1), splitString(re[i1].text, this.getBiaoLv()[i])), re.slice(i1 + 1))
                    }
                }
            }
            return re
        }
    },
    handleOKClick() {
        try {
            this.callBack(this.biaolv.selected)
        } catch (error) {

        }
        this.control(false)
    },
})

function findFirstUniqueIndices(longStr, substrings) {
    let res = []
    for (let i = 0; i < substrings.length; i++) {
        if (substrings[i] == null) continue
        res.push(parseInt(substrings[i]))
    }
    return res
    let usedIndices = new Set();  // 用来记录已经标记的索引
    let indices = [];

    // 遍历每个子字符串
    substrings.forEach(sub => {
        let pos = longStr.indexOf(sub);  // 查找子字符串首次出现的位置

        while (pos !== -1) {
            let canUse = true;
            // 检查该子字符串的所有字符的索引是否已被使用
            for (let i = 0; i < sub.length; i++) {
                if (usedIndices.has(pos + i)) {
                    canUse = false;  // 如果有任何字符索引被使用，跳过这个位置
                    break;
                }
            }

            if (canUse) {
                // 如果可以使用，存储每个字符的索引
                for (let i = 0; i < sub.length; i++) {
                    indices.push(pos + i);
                    usedIndices.add(pos + i);  // 标记这些索引为已使用
                }
                break;  // 确保只记录首次出现的位置
            }

            // 查找下一个匹配位置
            pos = longStr.indexOf(sub, pos + 1);
        }
    });

    // 返回按索引顺序排列的数组
    return indices.sort((a, b) => a - b);
}

onMounted(async () => {
    store.onChange('controlBiaoLvForm', () => {
        let data = store.getItem('controlBiaoLvForm')
        config.value.text = data.text
        config.value.callBack = data.callBack
        config.value.biaolv.selected = (data.subStrings == undefined ? [] : findFirstUniqueIndices(data.text, data.subStrings))
        // 22 // 11_2 // console.log(123, data.text, data.subStrings, data.subStrings == undefined ? [] : findFirstUniqueIndices(data.text, data.subStrings))
        config.value.control(data.op)
    })

    setInterval(() => {
        if (config.value.biaolv.previewColor == 'gray') {
            config.value.biaolv.previewColor = 'gray'
        } else {
            config.value.biaolv.previewColor = 'rgb(36, 194, 125)'
        }
    }, 500);
})
</script>

<style scoped></style>