<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: opacity .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            opacity: config.show <= 1 ? 0 : 1,
            padding: config.isVideo ? '150px' : '150px',
            paddingBottom: config.isVideo ? '20px' : '20px',
            paddingTop: config.isVideo ? '280px' : '280px',
        }">
        <div @click="config.control(false)"
            style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 10005;">

        </div>
        <div v-if="config.isVideo == false" @click="config.control(false)"
            style="width: 100%;height: 100%;background-repeat: no-repeat;background-size: contain;background-position: bottom;position: relative;z-index: 10010;"
            :style="{
                backgroundImage: `url(${config.url})`
            }">

        </div>
        <video controls v-if="config.isVideo"
            style="width: 100%;height: 100%;background-color: black;border-radius: 5px;position: relative;z-index: 10006;"
            :src="config.url"></video>
        <div @click="config.control(false)" class="close-button"
            style="width: 70px;height: 70px;display: inline-block;position: fixed;right: 0;top: 0;z-index: 10015;">
            <div
                style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: white;">
                <svg width="28" height="28" viewBox="0 0 48 48" fill="currentColor">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                        fill="currentColor" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const config = ref({
    show: 0,
    url: '',
    isVideo: false,
    async control(op, url = '') {
        this.isVideo = false
        if (op == 2) this.isVideo = true
        if (op) {
            this.url = url
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
            this.url = ''
        }
    }
})

onMounted(async () => {
    store.onChange('controlImagePreviewForm', () => {
        let data = store.getItem('controlImagePreviewForm')
        config.value.control(data.op, data.url)
    })
})
</script>

<style scoped>
.close-button {}

.close-button:hover {
    background-color: red;
}

.close-button:active {
    background-color: rgb(233, 0, 0);
}
</style>