<template>
    <div v-if="config.show"
        style="position: fixed;left: 0;top: 0;background-color: #fff;z-index: 9900;transition: opacity .3s cubic-bezier(0.1,0.9,0.2,1);width: 100vw;height: 100vh;"
        :style="{
            opacity: config.show > 1 ? 1 : 0, x
        }">

    </div>
</template>
 x
<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'

const config = ref({
    show: 2,
    transition: false,
    async control(op) {
        if (op) {
            this.show = 2
        } else {
            if (config.value.transition || 1) {
                this.show = 1
                await tool.wait(0.3)
            }
            this.show = 0
        }
    }
})

onMounted(async () => {
    store.onChange('controlCoverForm', () => {
        let data = store.getItem('controlCoverForm')
        config.value.transition = data.transition
        config.value.control(data.op)
    })
})
</script>

<style scoped></style>